(function ( $ ) {
     $.fn.menuLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var dropdown   = $('.dropdown');
        var head       = $('#head');
        var nav        = $('#head .nav');
        var opened     = false;

        function _init() {
            _disableLinks(objLink);
            _click(objLink);
        }

        function _disableLinks(o){
            $(o).click(function(e) {
                e.preventDefault();
                return true;
            });
        }

        function _click(o){
            $(o).click(function(e) {
                _closeNavigation();
                offset = 100;
                target = $(this).attr('href');
                $('html, body').animate({
                    scrollTop: $(target).offset().top - offset
                }, 500);
            });
        }

        function _closeNavigation(){
            $(nav).addClass('close');
            $(head).find('a.mobilNav').find('i').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }

        function _openNavigation(){
            $(nav).addClass('active').removeClass('close');
            $(head).find('a.mobilNav').find('i').removeClass('icon-menu').addClass('icon-close');
            $('body').addClass('locked');
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("#head ul.nav a").menuLink();

(function ( $ ) {
     $.fn.btnLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);

        function _init() {
            _disableLinks(objLink);
            _click(objLink);
        }

        function _disableLinks(o){
            $(o).click(function(e) {
                e.preventDefault();
                return true;
            });
        }

        function _click(o){
            $(o).click(function(e) {
                offset = 100;
                target = $(this).attr('href');
                $('html, body').animate({
                    scrollTop: $(target).offset().top - offset
                }, 500);
            });
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("a.btnLink").btnLink();


(function ( $ ) {
     $.fn.menuLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var dropdown   = $('.dropdown');
        var head       = $('#head');
        var nav        = $('#head .nav');
        var opened     = false;

        function _init() {
            $( objLink ).click(function() {
                if($('#head .nav').hasClass('close')){
                    _openNavigation();
                }else{
                    _closeNavigation();
                }
            });
        }

        function _closeNavigation(){
            $(nav).addClass('close');
            $(objLink).find('i').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }

        function _openNavigation(){
            $(nav).addClass('active').removeClass('close');
            $(objLink).find('i').removeClass('icon-menu').addClass('icon-close');
            $('body').addClass('locked');
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("#head a.mobilNav").menuLink();
